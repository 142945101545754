import React, { useRef, useState } from 'react';
import { TIPO_VISAO } from '~/modules/Helper/tipo-visao';
import { Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const FilterHeaderTable = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  close,
  visaoColuna,
  setDataFilterHeader,
  dataFilterHeader,
  configuracao,
  obterColunaChave,
  obterColunaLink,
  setFiltro,
  filtro,
  setPagina
}) => {
  const searchInput = useRef(null);

  const [valorChave, setValorChave] = useState('');
  const [campoAlterado, setCampoAlterado] = useState(false);

  const { NomeColuna, FonteDados, TipoValor } = visaoColuna;

  let KeyVisao = null;
  if (configuracao && configuracao.VisaoColunas) {
    const visaoColuna =
      configuracao.Tipo === TIPO_VISAO.AGRUPAMENTO
        ? obterColunaChave()
        : obterColunaLink();

    KeyVisao = visaoColuna?.FonteDados;
  }

  const handleSearch = (selectedKeys, confirm, valorChave) => {
    confirm();
    if (KeyVisao && KeyVisao == FonteDados) {
      setValorChave(valorChave);
      setFiltro([
        {
          Campo: FonteDados,
          Valor: selectedKeys[0],
          TipoValor,
        },
      ]);
      setSelectedKeys(['']);
    } else {
      let dataFilter = { ...dataFilterHeader };
      dataFilter.Filtros ? dataFilter.Filtros = dataFilter.Filtros.filter(x => x.Campo != FonteDados) : dataFilter.Filtros = [];
      dataFilter.Filtros.push({
        Campo: FonteDados,
        Valor: selectedKeys[0],
        TipoValor,
      });
      setDataFilterHeader(dataFilter);
    }
    setCampoAlterado(false);
    setPagina(1);
  };

  const handleReset = clearFilters => {
    clearFilters();
    if (KeyVisao && KeyVisao == FonteDados) setFiltro([]);
    else {
      let dataFilter = {
        ...dataFilterHeader,
        Filtros: dataFilterHeader.Filtros.filter(x => x.Campo != FonteDados),
      };
      setDataFilterHeader(dataFilter);
    }
    setValorChave('');
  };

  const verificaValor = () => {
    let valor = selectedKeys[0] || '';
    if (KeyVisao && KeyVisao == FonteDados) {
      if (valor == valorChave && campoAlterado) {
        setCampoAlterado(false);
      } else if (
        filtro?.length > 0 &&
        filtro[0].Campo == KeyVisao &&
        filtro[0].Valor != valorChave &&
        !campoAlterado
      ) {
        valor = filtro[0].Valor;
        setValorChave(valor);
      } else {
        valor =
          (valor != valorChave || valorChave) && campoAlterado
            ? valor
            : valorChave;
      }
    }
    return valor;
  };

  return (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={e => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`Buscar ${NomeColuna}`}
        value={verificaValor()}
        onChange={e => {
          setCampoAlterado(true);
          setSelectedKeys(e.target.value ? [e.target.value] : ['']);
        }}
        onPressEnter={e => {
          handleSearch(selectedKeys, confirm, e.target?.value);
        }}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          Filtrar
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Limpar
        </Button>
      </Space>
    </div>
  );
};

export default FilterHeaderTable;
